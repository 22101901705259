import React, {useContext} from 'react';
import {appConst, appSettings} from "../constant/appConst";
import {Redirect, Route} from "react-router-dom";
import UserContext from "./context/UserContext";
import {appUtil} from "../constant/appUtil";

const UserPrivateRoute = ({ component: Component, ...rest }) => {
    const {user,setUser} = useContext(UserContext);
    return <Route {...rest} render={(props) => (
        user ? (appUtil.isSelfPresentation() ?  <Redirect to='/' /> : <Component {...props} />) : <Redirect to='/' />
    )} />
}



export default UserPrivateRoute;