import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import "../css/UserMenu.css";
import UserContext from "./context/UserContext";
import {useHistory,withRouter} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import FavContext from "./context/FavContext";
import {apiConst} from "../constant/apiConst";
import AlertContext from "./context/AlertContext";
import {appSettings} from "../constant/appConst";
import {appUtil} from "../constant/appUtil";

const UserMenu = (props) => {
    const { user, setUser } = useContext(UserContext);
    const { client, setClient } = useContext(ClientContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const history = useHistory();

    function onClickLogout(){
        setConfirm({show:true,text:"You want to terminate session",onConfirm : onConfirm})
    }
    function onConfirm(){
        if(client) apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
        setUser(null);
        setClient(null);
        setFavs([]);
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("presentation_id");
        sessionStorage.removeItem("presentation_status");
        sessionStorage.removeItem("client");
        sessionStorage.removeItem("favs");
        history.push("/");
        if(client) setAlert("Activity is terminated");
    }

    function onCloseBtnClick() {
        document.querySelector(".user-menu").classList.add("d-none")
    }

    function onClickDashboard(){
        document.querySelector(".user-menu").classList.add("d-none")
        history.push("/dashboard");
    }



    return (
        <div className={"user-menu d-none"}>
            <button className={"close-button colored"} onClick={onCloseBtnClick}>
                <div></div>
                <div></div>
            </button>
            <div className="sub-user-menu ">
                <div className={"sub-user-menu-icon-container"}>
                    <div className="sub-user-menu-icon"></div>
                </div>
                <span className={"user-online"}><span className={"user-online-indicator"}></span>ONLINE</span>
                <div className={"mt-3"}>
                    <div className="user-menu-role">{user.role.name}</div>
                    <div className="user-menu-name">{user.name}</div>
                </div>
            </div>
            <div className={appUtil.isSelfPresentation() ? "user-menu-link-container d-none" : "user-menu-link-container"}>
                <div onClick={onClickDashboard} className={"user-menu-link dashboard-link"}>
                    <button className={appUtil.isSelfPresentation() ? "default-button btn-disabled" : "default-button" }  >DASHBOARD</button>
                </div>
                <div onClick={onClickLogout} className={"user-menu-link logout-link"}>
                    <button className={appUtil.isSelfPresentation() ? "default-button btn-disabled" : "default-button" }  >LOG OUT</button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(UserMenu);