import React, { useState, useEffect, useContext } from 'react';
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import ClientContext from "./context/ClientContext";
import UserContext from "./context/UserContext";
import FavContext from "./context/FavContext";
import {Link,useHistory} from "react-router-dom";


function ClientInvite() {

  const { user, setUser } = useContext(UserContext);
  const [fields, setFields] = useState({email:""});
  const [errors, setErrors] = useState({email:""});
  const { alert, setAlert } = useContext(AlertContext);
  const [isLoading,setIsLoading] = useState(false);
  const history = useHistory();

  function onInvite()
  {

    if(handleValidation())
    {
      setIsLoading(true);
      apiConst.clientCheck(fields["email"],user.id).then(result => {

        if(result.response)
        {
          apiConst.clientInvite(fields,user.id).then(result => {
            if(result.response)
            {
              history.push("/clientInvitationWait/"+ result.invitation.id)
            }
            else
            {
              setAlert("Server error, try again later")
            }
            setIsLoading(false);
          });

        }
        else {

          errors["email"] = result.message;
          setErrors(errors);
          setIsLoading(false);

        }
      })

    }
  }




  function handleValidation(){
    let errors = {};
    let formIsValid = true;


    //Email
    if(!fields["email"])
    {
      formIsValid = false;
      errors["email"] = "Please type your email";
    }
    else
    {
      if(typeof fields["email"] !== "undefined"){
        let lastAtPos = fields["email"].lastIndexOf('@');
        let lastDotPos = fields["email"].lastIndexOf('.');

        if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      }
    }



    setErrors(errors)
    return formIsValid;
  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (
      <section  className="sign-section">
    <div className="sign-wrap">


        <div className="inner">
          <div className="logo"><img src={require("../img/logo-splash.svg")}  /></div>
          <h2>INVITE CLIENT</h2>

          <div className="form-element">
            <input type="text" className={errors["email"] ? "form-control is-invalid" : "form-control"}  id="email" placeholder="E-mail" onChange={(e) => handleChange("email",e)} />
            {
              errors["email"] && <div className="invalid-feedback">
                {errors["email"]}
              </div>
            }
          </div>



          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onInvite} label="INVITE EXISTING CLIENT" isLoading={isLoading} />

          </div>

          <div className="mt-4 ">
            <div className="seperator-text">
              OR
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/register">REGISTER NEW CLIENT</Link>
          </div>
          <div className="mt-3">
            <Link className={"default-button lined login"} to="/login">LOGIN AS CLIENT</Link>
          </div>


        </div>





    </div>
        {!user && <Link to="/salesLogin" className="sales-login-link">Login as Sales Person</Link>}
        {user && <Link to="/" className="sales-login-link">Go Home</Link>}
      </section>
  );
}

export default ClientInvite;