import React, {useState, useEffect, useContext} from 'react';
import {
    Link, withRouter, useHistory, useLocation
} from "react-router-dom";
import Filter from './FilterSubMenu.js';
import UserContext from "./context/UserContext.js";
import '../css/MainMenu.css';
import SubMenu from "./SubMenu";
import '../constant/appConst';
import {appConst, appSettings} from "../constant/appConst";
import AppDataContext from "./context/AppDataContext";
import ClientMenu from "./ClientMenu";
import ClientContext from "./context/ClientContext";
import UserMenu from "./UserMenu";
import FavoritesSubMenu from "./FavoritesSubMenu";
import LocationSubMenu from "./LocationSubMenu";
import FilterContext from "./context/FilterContext";
import GallerySubMenu from "./GallerySubMenu";
import SearchSubMenu from "./SearchSubMenu";

function MainMenu(props) {

    const [isMenuNarrow, setIsMenuNarrow] = useState(false);
    const [showFilterSubMenu, setShowFilterSubMenu] = useState(false);
    const [showGallerySubMenu, setShowGallerySubMenu] = useState(false);
    const [showLocationSubMenu, setShowLocationSubMenu] = useState(false);
    const [showSearchSubMenu, setShowSearchSubMenu] = useState(false);
    const [showFavoritesSubMenu, setShowFavoritesSubMenu] = useState(false);
    const {user, setUser} = useContext(UserContext);
    const {client, setClient} = useContext(ClientContext);
    const {appData, setAppData} = useContext(AppDataContext);
    const history = useHistory();
    const [activePage, setActivePage] = useState("");
    const location = useLocation();


    useEffect(() => {
        if (document.querySelector(".user-menu"))
            document.querySelector(".user-menu").classList.add("d-none")

        if (document.querySelector(".client-menu"))
            document.querySelector(".client-menu").classList.add("d-none")

        document.body.className = "";
        if (location.pathname === "/") {
            document.body.classList.add("page-home")
            document.querySelector(".main-menu").classList.remove('narrow');
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFavoritesSubMenu(false);
            setShowFilterSubMenu(false)
            setShowSearchSubMenu(false)
            setActivePage("home")
        } else if (location.pathname.indexOf("/location") > -1) {
            document.body.classList.add("page-location")
            document.querySelector(".main-menu").classList.add('narrow');
            document.querySelector("section.primary").classList.remove('without-submenu');
            setShowFavoritesSubMenu(false);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(true);
            setShowFilterSubMenu(false)
            setShowSearchSubMenu(false)
            setActivePage("location")
        } else if (location.pathname.indexOf("/gallery") > -1) {
            document.body.classList.add("page-gallery")
            document.querySelector(".main-menu").classList.add('narrow');
            document.querySelector("section.primary").classList.remove('without-submenu');
            setShowFavoritesSubMenu(false);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFilterSubMenu(false)
            setShowSearchSubMenu(false)
            setActivePage("gallery")

        } else if (location.pathname.indexOf("/favorites") > -1) {
            document.body.classList.add("page-favorites")
            document.querySelector(".main-menu").classList.add('narrow');
            document.querySelector("section.primary").classList.remove('without-submenu');
            setShowFavoritesSubMenu(true);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFilterSubMenu(false)
            setShowSearchSubMenu(false)
            setActivePage("favorites")

        }
        else if (location.pathname.indexOf("/property-view") > -1) {
            document.body.classList.add("page-property-view")
            document.querySelector(".main-menu").classList.add('narrow');
            setShowFavoritesSubMenu(false);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFilterSubMenu(false)
            setShowSearchSubMenu(false)
            setActivePage("property-view")

        }
        else if (location.pathname.indexOf("/map-view") > -1) {
            document.body.classList.add("page-map-view")
            document.querySelector(".main-menu").classList.add('narrow');
            setShowFavoritesSubMenu(false);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFilterSubMenu(true)
            setShowSearchSubMenu(false)
            setActivePage("map-view")

        }
        else if (location.pathname.indexOf("/list-view") > -1) {
            console.log("list-view")
            document.body.classList.add("page-list-view")
            document.querySelector(".main-menu").classList.add('narrow');
            document.querySelector("section.primary").classList.remove('without-submenu');
            /*setShowFavoritesSubMenu(false);
            setShowGallerySubMenu(false);
            setShowLocationSubMenu(false);
            setShowFilterSubMenu(true)
            setShowSearchSubMenu(false)
            setActivePage("list-view")*/
        }
        else {
            document.querySelector(".main-menu").classList.add('narrow');
        }

    }, [location]);


    useEffect(() => {
        if (showFilterSubMenu) {

            document.querySelector(".main-menu").classList.add('narrow');
            setShowGallerySubMenu(false);
            setShowSearchSubMenu(false);
            closePopups()
        }


    }, [showFilterSubMenu])

    useEffect(() => {
        if (showGallerySubMenu) {
            document.querySelector(".main-menu").classList.add('narrow');
            setShowFilterSubMenu(false);
            setShowSearchSubMenu(false);
            closePopups()
        }


    }, [showGallerySubMenu])

    useEffect(() => {
        if (showSearchSubMenu) {
            document.querySelector(".main-menu").classList.add('narrow');
            setShowFilterSubMenu(false);
            setShowGallerySubMenu(false);
            closePopups()
        }


    }, [showSearchSubMenu])

    useEffect(() => {
        if (!appData.isFilterModeActive) {
            setShowFilterSubMenu(false);

        }
    }, [appData])

    function onClickFilter() {
        if (appSettings.showRegisterClientPage) {
            if (user) {
                history.push("/map-view")
                setShowFilterSubMenu(!showFilterSubMenu);
            } else
                history.push("/register")
        } else {
            //history.push("/map-view")
            setShowFilterSubMenu(!showFilterSubMenu);
        }
    }


    function onClickLogin() {
        history.push("/salesLogin")

    }

    function closePopups(){
        if(document.querySelector(".user-menu"))
            document.querySelector(".user-menu").classList.add("d-none")
        if(document.querySelector(".client-menu"))
            document.querySelector(".client-menu").classList.add("d-none")
    }

    function onClickUserPopup() {
        closeSubMenus();
        if(document.querySelector(".user-menu"))
            document.querySelector(".user-menu").classList.remove("d-none")
        if(document.querySelector(".client-menu"))
            document.querySelector(".client-menu").classList.add("d-none")
    }

    function onClickClientPopup() {
        closeSubMenus();
        if(document.querySelector(".client-menu"))
            document.querySelector(".client-menu").classList.remove("d-none")
        if(document.querySelector(".user-menu"))
            document.querySelector(".user-menu").classList.add("d-none")

    }

    function closeSubMenus(){
        if(document.querySelector("section.primary"))
        document.querySelector("section.primary").classList.add('without-submenu');
        setShowFavoritesSubMenu(false);
        setShowGallerySubMenu(false);
        setShowLocationSubMenu(false);
        setShowFilterSubMenu(false)
        setShowSearchSubMenu(false)
    }


    function onClickGallery() {
        if (appSettings.showRegisterClientPage) {
            if (user) {
                setShowGallerySubMenu(!showGallerySubMenu);

            } else
                history.push("/register")
        } else {
            setShowGallerySubMenu(!showGallerySubMenu);
        }


    }

    function onClickLocation() {
        setIsMenuNarrow(true)
        if (appSettings.showRegisterClientPage) {
            if (user) {
                setShowLocationSubMenu(true);
            } else
                history.push("/register")
        } else {
            setShowLocationSubMenu(true);
        }
    }

    function onClickSearch() {
        if (appSettings.showRegisterClientPage) {
            if (user) {
                setShowSearchSubMenu(!showSearchSubMenu);

            } else
                history.push("/register")
        } else {
            setShowSearchSubMenu(!showSearchSubMenu);
        }
    }

    window.addEventListener('panSliderMoved',function(){
        if(showGallerySubMenu) setShowGallerySubMenu(false);
        if(showSearchSubMenu) setShowSearchSubMenu(false);
        if(document.querySelector(".user-menu"))
            document.querySelector(".user-menu").classList.add("d-none")
        if(document.querySelector(".client-menu"))
            document.querySelector(".client-menu").classList.add("d-none")
    })

    return (


        <div>

            <section className="main-menu">

                <div className="menu-wrap">

                    <Link to="/" className="item home">
                        <div className="inner"></div>
                    </Link>
                    <div>
                        <div className={activePage === "search" || showSearchSubMenu ? "item search first active" : "item search first"}
                             onClick={onClickSearch}>
                            <div className="inner"><span>SEARCH</span></div>
                        </div>
                        <Link
                            to={appSettings.showRegisterClientPage ? (user ? "/location/all/0" : "/register") : "/location/0"}
                            className={activePage === "location" ? "item location active" : "item location"}
                            onClick={onClickLocation}>
                            <div className="inner"><span>LOCATION</span></div>
                        </Link>

                        <div className={activePage === "gallery" || showGallerySubMenu ? "item gallery active" : "item gallery"}
                             onClick={onClickGallery}>
                            <div className="inner"><span>GALLERY</span></div>
                        </div>

                        <div className={showFilterSubMenu ? "item filter active" : "item filter"}
                             onClick={onClickFilter}>
                            <div className="inner"><span>FILTER</span></div>
                        </div>
                    </div>


                    <div className="menu-footer">
                        <div className={"client-login-container"}>
                            {!client &&
                                <Link className="default-button login-btn"
                                      to="/inviteClient"><span>INVITE<br/>CLIENT</span></Link>
                            }
                            {client &&
                                <div>
                                    <button className="default-button login-btn" onClick={onClickClientPopup}>
                                        <div className={"online-indicator"}></div>
                                        <span>{client.first_name + " " + client.last_name}</span></button>
                                    <ClientMenu/>
                                </div>
                            }

                        </div>
                        <div className={"user-login-container"}>

                            {!user &&
                                <button className="default-button login-btn" onClick={onClickLogin}><span>SALES<br/>LOGIN</span>
                                </button>
                            }
                            {user &&
                                <div >
                                    <button className="default-button login-btn " onClick={onClickUserPopup}>
                                        <div className={"online-indicator"}></div>
                                        <span>{user.name}</span></button>
                                    <UserMenu/>
                                </div>
                            }
                        </div>

                    </div>

                </div>


                {showFilterSubMenu && <Filter/>}

                {(showFavoritesSubMenu && client) &&
                    <FavoritesSubMenu/>
                }

                {showGallerySubMenu &&
                    <GallerySubMenu/>
                }

                {showLocationSubMenu &&
                    <LocationSubMenu/>
                }
                {showSearchSubMenu &&
                    <SearchSubMenu/>
                }

            </section>


        </div>


    );

}

export default withRouter(MainMenu);