import React, { useState, useEffect, useContext } from 'react';
import {apiConst} from "../constant/apiConst";
import ButtonWithLoading from "./ButtonWithLoading";
import AlertContext from "./context/AlertContext";
import ClientContext from "./context/ClientContext";
import UserContext from "./context/UserContext";
import FavContext from "./context/FavContext";
import {Link,useHistory,useLocation} from "react-router-dom";
import {appConst, appSettings} from "../constant/appConst";


function ClientRegister() {

  const { client, setClient } = useContext(ClientContext);
  const { favs, setFavs } = useContext(FavContext);
  const { user, setUser } = useContext(UserContext);
  const [fields, setFields] = useState({email:"",password:"",first_name:"",last_name:"",phone:""});
  const [errors, setErrors] = useState({email:"",password:"",first_name:"",last_name:"",phone:""});
  const { alert, setAlert } = useContext(AlertContext);
  const [isLoading,setIsLoading] = useState(false);
  const history = useHistory();
  const query = useQuery();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    if(client)
    {
      console.log(client)
      setFavs(client.favs);
      sessionStorage.setItem("favs",JSON.stringify(client.favs));

      apiConst.presentationCreate(client.user.id,client.id).then(result => {
        if(result.response)
        {
          sessionStorage.setItem("client",JSON.stringify(client));
          sessionStorage.setItem("presentation_id",result.presentation.id);
          setAlert("Hello " + client.first_name + " " + client.last_name);
          history.push("/");
        }
      })
    }

  },[client]);

  useEffect(() => {
    if(user)
    {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("presentation_id");
      sessionStorage.removeItem("client");
      sessionStorage.removeItem("favs");
      sessionStorage.setItem("user",JSON.stringify(user));
    }

  },[user]);

  function onCreate()
  {

    if(handleValidation() )
    {
      setIsLoading(true);
      apiConst.clientCheck(fields["email"]).then(result => {


        if(result.response)
        {
          errors["password"] = "";
          errors["first_name"] = "";
          errors["last_name"] = "";
          errors["phone"] = "";
          errors["email"] = "Email is already taken";
          setErrors(errors);
          setIsLoading(false);
        }
        else {
          apiConst.clientCreate(fields,user ? user.id : 0, + appSettings.clientVerification).then(result => {
            if(result.response)
            {
              if(appSettings.clientVerification) {
                history.push("/verify/"+result.client.id)
              }
              else{
                console.log(result)
                //setUser(result.client.user);
                //setClient(result.client);
                history.push("/formResult/thankYouRegister")
              }

            }
            else
            {
              setAlert("Server error, try again later")
            }
            setIsLoading(false);
          });
        }
      })



    }
  }



  function handleValidation(){
    let errors = {};
    let formIsValid = true;




    if(!fields["first_name"]){
      formIsValid = false;
      errors["first_name"] = "Please type your first name";
    }
    else {
      if(fields["first_name"].length < 2) {
        formIsValid = false;
        errors["first_name"] = "Your name is too short";
      }
    }

    if(!fields["last_name"]){
      formIsValid = false;
      errors["last_name"] = "Please type your last name";
    }
    else {
      if(fields["last_name"].length < 2) {
        formIsValid = false;
        errors["last_name"] = "Your name is too short";
      }
    }


    if(!fields["phone"]){
      formIsValid = false;
      errors["phone"] = "Please type your phone number";
    }

    setErrors(errors)
    return formIsValid;
  }


  function handleChange(field,e)
  {
    let newFields = {...fields};
    newFields[field] = e.target.value;
    setFields(newFields);
  }

  return (
      <section  className="sign-section">
    <div className="sign-wrap">


        <div className="inner">
          <div className="logo"><img src={require("../img/logo-splash.svg")}  /></div>
          <p >Welcome to the world of {appSettings.projectName}! To take a tour with us, Please register your details below and we will be in touch!</p>
          <div className="form-element">
            <input type="text" className={errors["first_name"] ? "form-control is-invalid" : "form-control"}  id="first_name" defaultValue={query.get("first_name") || ""} placeholder="First Name" onChange={(e) => handleChange("first_name",e)} />
            {
              errors["first_name"] && <div className="invalid-feedback">
                {errors["first_name"]}
              </div>
            }
          </div>
          <div className="form-element">
            <input type="text" className={errors["last_name"] ? "form-control is-invalid" : "form-control"}  id="last_name" defaultValue={query.get("last_name") || ""} placeholder="Last Name" onChange={(e) => handleChange("last_name",e)} />
            {
              errors["last_name"] && <div className="invalid-feedback">
                {errors["last_name"]}
              </div>
            }
          </div>
          <div className="form-element">
            <input type="text" className={errors["email"] ? "form-control is-invalid" : "form-control"}  id="email" placeholder="E-mail" defaultValue={query.get("email") || ""} onChange={(e) => handleChange("email",e)} />
            {
              errors["email"] && <div className="invalid-feedback">
                {errors["email"]}
              </div>
            }
          </div>

          <div className="form-element">
            <input type="text" className={errors["phone"] ? "form-control is-invalid" : "form-control"}  id="phone" placeholder="Phone" defaultValue={query.get("phone") || ""} onChange={(e) => handleChange("phone",e)} />
            {
              errors["phone"] && <div className="invalid-feedback">
                {errors["phone"]}
              </div>
            }
          </div>

          <div className="mt-4">
            <ButtonWithLoading className={"default-button login"} onClick={onCreate} label="REGISTER" isLoading={isLoading} />

          </div>

          <div className="mt-4 ">
            <div className="seperator-text">
              OR
            </div>
          </div>
          <div className="mt-4">
            <Link className={"default-button lined login"} to="/login">LOGIN AS CLIENT</Link>
          </div>
          <div className="mt-3">

            {!user && <Link className={"default-button lined login"} to="/">GO HOME</Link>}
            {user && <Link className={"default-button lined login"} to="/inviteClient">INVITE CLIENT</Link>}
          </div>


        </div>





    </div>
        {!user && <Link to="/salesLogin" className="sales-login-link">Login as Sales Person</Link>}
        {user && <Link to="/" className="sales-login-link">Go Home</Link>}
      </section>
  );
}

export default ClientRegister;